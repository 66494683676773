import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import PlayerNews from "./PlayerNews";
import { mp_track } from "../mixpanel";

import classes from "./TeamNewsView.module.css";

const team_news_mp_track = (team, event, properties = null) => {
    properties = {
        ...properties,
        team_id: team.id,
        team_name: team.display_name,
    };

    mp_track(event, properties);
};

const TeamNewsView = ({ teamId, onContentLoading }) => {
    const teams = useSelector((state) => state.teams.teams);
    const team = teams && teams[teamId];

    const teamIdRef = useRef(null);

    useEffect(() => {
        if (team && teamIdRef.current !== team.id) {
            team_news_mp_track(team, "View NHL Team News");
            teamIdRef.current = team.id;
        }
    }, [team]);

    useEffect(() => {
        if (team) {
            onContentLoading && onContentLoading(false);
        } else {
            onContentLoading && onContentLoading(true);
        }
    }, [team, onContentLoading]);

    return (
        <div className={classes.team_news_container}>
            <div className={classes.team_news_content}>
                <PlayerNews teamId={teamId} />
            </div>
        </div>
    );
};

export default TeamNewsView;
